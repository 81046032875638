<template>
  <div>
    <div class="top-banner">
      <div class="banner-text">
        <div class="banner-title">智慧养老</div>
        <div class="banner-subtitle">
          涵盖居家养⽼、机构养⽼、医养结合等多种模式。围绕⽼⼈建⽴⾃动监测、应急预警等健康保障能⼒，提⾼养⽼机构紧急情况响应速度和机构管理效率，赋能医疗资源与养⽼资源相结合发展模式。
        </div>
      </div>
      <img src="@/assets/images/ban9-1.png" alt="" />
    </div>

    <div class="process">
      <div class="type-area">
        <div class="divider">
          <el-divider>智慧养老</el-divider>
        </div>
        <div class="base-top">
          <div class="dot-plot">档案化管理</div>
        </div>
        <div class="process-main main">
          <img src="@/assets/images/banner27.png" alt="" />
          <div class="mian-first">
            <div v-for="item in archivalManagementList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot" style="text-align: left">监测报警</div>
        </div>
        <div class="engine-main main">
          <div class="mian-first">
            <div v-for="item in monitorAlarmsList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner28.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process task">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot">安全保障</div>
        </div>
        <div class="task-main main">
          <img src="@/assets/images/banner29.png" alt="" />
          <div class="mian-first">
            <div v-for="item in securityGuaranteesList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine system">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot" style="text-align: left">响应协同</div>
        </div>
        <div class="system-main main">
          <div class="mian-first">
            <div v-for="item in responsiveCollaborationList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner30.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process task2">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot">全局分析</div>
        </div>
        <div class="task2-main main">
          <img src="@/assets/images/banner31.png" alt="" />
          <div class="mian-first">
            <div v-for="item in globalAnalysisList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contact_us">
      <div class="type-area">
        <span>请联系我们, 您将获得全程支持咨询服务</span>
        <el-button type="primary">联系我们</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { caseTag, caseGet } from "@/api/request";

export default {
  name: "tech", // 开放能力
  data() {
    return {
      archivalManagementList: [
        {
          title: "档案化管理",
          subTitle:
            "展示辖区内⽼⼈分布，点击可以查看⽼⼈档案信息，⽀持按照姓名、类型精确搜索⽼⼈。",
        },
      ],
      monitorAlarmsList: [
        {
          title: "监测报警",
          subTitle:
            "实时监测⽼⼈⽣命体征、⾏进轨迹，当⽼⼈超出电⼦围栏或发⽣紧急情况时系统⾃动向亲属和养⽼机构发送告警信息，⼤⼤缩短救援时间。",
        },
      ],
      securityGuaranteesList: [
        {
          title: "安全保障",
          subTitle:
            "针对性进⾏路径规划，规避事故⾼发、容易堵⻋等路段，根据⽼⼈轨迹和速度信息⾃动化判定⽼⼈运动状态，保障⾏⻋安全。",
        },
      ],
      responsiveCollaborationList: [
        {
          title: "响应协同",
          subTitle:
            "运⾏管理中⼼实时感知⽼⼈异常⾏为和危险态势，发⽣紧急情况时系统⾃动指派距离最近的⼯作⼈员赶往现场，⽼⼈也可以通过⼀键呼救、⾳视频通话等物联⽹硬件直连指挥中⼼。",
        },
      ],
      globalAnalysisList: [
        {
          title: "全局分析",
          subTitle:
            "基于地图对住区分布、养⽼机构分布、医疗资源可达性、交通状况等维度分析养⽼服务设施的空间分布情况，分析养⽼设施的覆盖率及区域差异，为地⽅政府优化养⽼服务设施规划提供参考。",
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}

.dot-plot {
  width: 1182px;
  height: 137px;
  text-align: right;
  background: url("~@/assets/images/wangdian.png") no-repeat;
  background-size: 1182px 137px;
  padding-right: 16px;
  padding-top: 36px;
  font-size: 28px;
  line-height: 28px;
}
.divider {
  padding: 0px 250px;
  & /deep/.el-divider__text {
    font-size: 36px;
    font-weight: 400;
    color: #17191f;
    line-height: 36px;
    padding: 0 52px;
    background-color: #fbfbfb;
  }
  &/deep/.el-divider {
    background-color: #d1d6e0;
    height: 1px;
  }
}
.mian-first {
  width: 454px;
  justify-content: space-evenly;
  div {
    display: flex;
    span {
      color: #797c80ff;
      font-size: 16px;
      font-face: AlibabaPuHuiTi;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0;
      paragraph-spacing: 0;
      text-align: left;
    }
  }
}
.top-banner {
  display: flex;
  justify-content: space-between;
  height: 580px;
  width: 100%;
  background: url("~@/assets/images/ban9.png") no-repeat;
  background-color: #010d14;
  background-size: 1800px 580px;
  background-position: top;
  background-repeat: no-repeat;
  color: #ffffff;
  overflow: hidden;
  img {
    width: 837px;
    height: 433px;
    object-fit: contain;
    margin-top: 75px;
  }
  .banner-text {
    margin: 0 auto;
  }
  .banner-title {
    font-size: 60px;
    font-weight: 400;
    line-height: 82px;
    margin-top: 166px;
  }
  .banner-subtitle {
    font-size: 16px;
    line-height: 30px;
    margin-top: 27px;
    width: 528px;
    height: 180px;
    color: #b6bfd3;
  }
}
.base-top {
  height: 118px;
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1165px;
  & > img {
    object-fit: contain;
    width: 592.17px;
    height: 293px;
    // box-shadow: 0 4px 43px 0 #808ca269;
    filter: drop-shadow(0 4px 43px #808ca269);
  }
  & > div {
    div {
      display: flex;
      flex-direction: column;
      margin-top: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #797c80;
      line-height: 26px;
      &:first-child {
        margin-top: 0;
      }
      span {
        height: 20px;
        font-family: AlibabaPuHuiTi-Medium;
        font-weight: 500;
        font-size: 20px;
        color: #1e1e1e;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 22px;
      }
    }
  }
}

.process {
  background: #ffffff;
  height: 880px;
  padding-top: 100px;
  .divider {
    padding: 0px 250px 105px;
    & /deep/.el-divider__text {
      font-size: 36px;
      font-weight: 400;
      color: #17191f;
      line-height: 36px;
      padding: 0 52px;
      background-color: #ffffff;
    }
    &/deep/.el-divider {
      background-color: #d1d6e0;
      height: 1px;
    }
  }
  .process-main {
    margin-top: 87px;
  }
}

.engine {
  padding-top: 80px;
  height: 800px;
  background: #f5f7f9;

  .engine-main {
    margin-top: 174px;
    & > img {
      object-fit: contain;
      width: 592px;
      height: 294px;
    }
  }
}

.task {
  padding-top: 80px;
  height: 800px;
  height: 800px;
  .task-main {
    margin-top: 126px;
    & > img {
      width: 592.17px;
      height: 293px;
    }
  }
}

.system {
  padding-top: 80px;
  height: 800px;
  .system-main {
    margin-top: 174px;
    & > img {
      width: 590.87px;
      height: 300px;
    }
  }
}

.task2 {
  padding-top: 80px;
  height: 800px;
  .task2-main {
    margin-top: 38px;
    & > img {
      width: 606.35px;
      height: 464.95px;
    }
  }
}

.contact_us {
  height: 289px;
  background: #ffffff;
  .type-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 88px;
  }
  span {
    font-size: 28px;
    line-height: 28px;
    color: #393c40;
    font-weight: 500;
    margin-bottom: 33px;
  }
  &/deep/.el-button {
    width: 161px;
    height: 56px;
    font-size: 20px;
    font-weight: 400;
    border-radius: 0;
    background: #0f80dc;
    border-color: #0f80dc;
    &:hover {
      background: #108aee;
      border-color: #108aee;
    }
  }
}
</style>
